<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本名称" prop="scriptId">
                <a-input v-model="queryParam.name" placeholder="请输入剧本名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="用户手机号" prop="userMobile">
                  <a-input v-model="queryParam.userMobile" placeholder="请输入用户手机号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用户名称" prop="nickName">
                  <a-input v-model="queryParam.nickName" placeholder="请输入用户名称" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['order:order/script:export']">
          <a-icon type="download"/>
          导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="orderStatus" slot-scope="text, record">
           <custom-dict-tag :options="customDict.OrderStatusEnum" :value="record.orderStatus"/>
        </span>

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:order/script:edit']"/>
           <a @click="$refs.createForm.detail(record, undefined)">
            <a-icon type="edit"/>订单详情
          </a>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listOrderScript, delOrderScript} from '@/api/order/orderScript'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'OrderScript',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        groupId: null,
        scriptId: null,
        scriptInfo: null,
        atvInfo: null,
        userId: null,
        userMobile: null,
        nickName: null,
        shopId: null,
        shopName: null,
        name: null,
        orderCount: null,
        originalPrice: null,
        orderAmount: null,
        payPrice: null,
        payAmount: null,
        discountAmount: null,
        payChannel: null,
        payTime: null,
        isDisplay: null,
        isForce: null,
        discountInfo: null,
        orderStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '手机号',
          dataIndex: 'userMobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户名称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '实际订单总额',
          dataIndex: 'payAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '折扣金额',
          dataIndex: 'discountAmount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: {customRender: 'payTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          ellipsis: true,
          scopedSlots: {customRender: 'orderStatus'},
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询订单-预约打本订单列表 */
    getList() {
      this.loading = true
      listOrderScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        groupId: undefined,
        scriptId: undefined,
        scriptInfo: undefined,
        atvInfo: undefined,
        userId: undefined,
        userMobile: undefined,
        nickName: undefined,
        shopId: undefined,
        shopName: undefined,
        name: undefined,
        orderCount: undefined,
        originalPrice: undefined,
        orderAmount: undefined,
        payPrice: undefined,
        payAmount: undefined,
        discountAmount: undefined,
        payChannel: undefined,
        payTime: undefined,
        isDisplay: undefined,
        isForce: undefined,
        discountInfo: undefined,
        orderStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delOrderScript(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('order/order/script/export', {
            ...that.queryParam
          }, `order/script_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
