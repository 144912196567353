<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="店铺名称" prop="shopName" >
        <a-input v-model="form.shopName" disabled />
      </a-form-model-item>
      <a-form-model-item label="剧本名称" prop="name" >
        <a-input v-model="form.name" disabled />
      </a-form-model-item>
      <a-form-model-item label="用户手机号" prop="userMobile" >
        <a-input v-model="form.userMobile" disabled />
      </a-form-model-item>
      <a-form-model-item label="用户名称" prop="nickName" >
        <a-input v-model="form.nickName" disabled />
      </a-form-model-item>
      <a-form-model-item label="数量" prop="orderCount" >
        <a-input v-model="form.orderCount" disabled />
      </a-form-model-item>
      <a-form-model-item label="实际订单单价" prop="payPrice" >
        <a-input v-model="form.payPrice" disabled />
      </a-form-model-item>
      <a-form-model-item label="实际订单总额" prop="payAmount" >
        <a-input v-model="form.payAmount" disabled />
      </a-form-model-item>
      <a-form-model-item label="支付渠道" prop="payChannel">
        <a-select style="width: 250px" v-model="form.payChannel"disabled>
          <a-select-option v-for="(item, index) in this.customDict.PayInfoEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="订单状态" prop="orderStatus">
        <a-select style="width: 250px" v-model="form.orderStatus" disabled>
          <a-select-option v-for="(item, index) in this.customDict.OrderStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="支付时间" prop="payTime" disabled >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" disabled allow-clear/>
      </a-form-model-item>
      <!--      <a-form-model-item label="优惠信息 json 存放优惠的信息以及金额" prop="discountInfo" >
            </a-form-model-item>-->
      <a-form-model-item label="优惠信息" prop="discountInfo">
        <template>
          <a-card>
            <a-form-model-item label="门票名称" prop="discountInfo.ticketName">
              <a-input v-model="form.discountInfo.ticketName" placeholder="请输入内容" type="textarea" disabled allow-clear />
            </a-form-model-item>
            <a-form-model-item label="门票优惠金额" prop="discountInfo.ticketAmount">
              <a-input v-model="form.discountInfo.ticketAmount" placeholder="请输入内容" type="textarea" disabled allow-clear />
            </a-form-model-item>
            <a-form-model-item label="优惠券名称" prop="discountInfo.couponName">
              <a-input v-model="form.discountInfo.couponName" placeholder="请输入内容" type="textarea" disabled allow-clear />
            </a-form-model-item>
            <a-form-model-item label="优化券优惠金额" prop="discountInfo.couponAmount">
              <a-input v-model="form.discountInfo.couponAmount" placeholder="请输入内容" type="textarea" disabled allow-clear />
            </a-form-model-item>
          </a-card>
        </template>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark"  >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea"  disabled allow-clear />
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrderScript, addOrderScript, updateOrderScript } from '@/api/order/orderScript'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        groupId: null,

        scriptId: null,

        scriptInfo: null,

        atvInfo: null,

        userId: null,

        userMobile: null,

        nickName: null,

        shopId: null,

        shopName: null,

        name: null,

        orderCount: null,

        originalPrice: null,

        orderAmount: null,

        payPrice: null,

        payAmount: null,

        discountAmount: null,

        payChannel: null,

        payTime: null,

        isDisplay: null,

        isForce: null,

        orderStatus: 0,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,
        discountInfo: {
          ticketId: null,
          ticketName: null,
          ticketAmount: null,
          couponId: null,
          couponName: null,
          couponAmount: null
        },

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        groupId: [
          { required: true, message: '组局id order_group.id不能为空', trigger: 'blur' }
        ],

        scriptId: [
          { required: true, message: '剧本id script_script.id不能为空', trigger: 'blur' }
        ],

        scriptInfo: [
          { required: true, message: '剧本信息快照 json 包含剧本基本信息和价格不能为空', trigger: 'blur' }
        ],

        userId: [
          { required: true, message: '用户id user_user.id不能为空', trigger: 'blur' }
        ],

        userMobile: [
          { required: true, message: '用户手机号不能为空', trigger: 'blur' }
        ],

        nickName: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],

        shopId: [
          { required: true, message: '店铺id user_user.id不能为空', trigger: 'blur' }
        ],

        shopName: [
          { required: true, message: '店铺名称不能为空', trigger: 'blur' }
        ],

        orderCount: [
          { required: true, message: '数量不能为空', trigger: 'blur' }
        ],

        originalPrice: [
          { required: true, message: '原价不能为空', trigger: 'blur' }
        ],

        orderAmount: [
          { required: true, message: '订单总金额不能为空', trigger: 'blur' }
        ],

        payPrice: [
          { required: true, message: '实际订单单价不能为空', trigger: 'blur' }
        ],

        payAmount: [
          { required: true, message: '实际订单总额不能为空', trigger: 'blur' }
        ],

        discountAmount: [
          { required: true, message: '折扣金额不能为空', trigger: 'blur' }
        ],

        payChannel: [
          { required: true, message: '支付渠道 1:门票,10:微信,50:支付宝不能为空', trigger: 'blur' }
        ],

        isDisplay: [
          { required: true, message: '显示标记 1:显示,0:不显示不能为空', trigger: 'blur' }
        ],

        isForce: [
          { required: true, message: '是否强制验签 后台人员强制验签 订单不可退款不能为空', trigger: 'blur' }
        ],

        orderStatus: [
          { required: true, message: '订单状态  2100:已创建,2110:待付款,2120:待验券,2130:已完成(未评价),2131:已完成,2140:已退款,2150:已取消不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        groupId: null,

        scriptId: null,

        scriptInfo: null,

        atvInfo: null,

        userId: null,

        userMobile: null,

        nickName: null,

        shopId: null,

        shopName: null,

        name: null,

        orderCount: null,

        originalPrice: null,

        orderAmount: null,

        payPrice: null,

        payAmount: null,

        discountAmount: null,

        payChannel: null,

        payTime: null,

        isDisplay: null,

        isForce: null,

        discountInfo: {
          ticketId: null,
          ticketName: null,
          ticketAmount: null,
          couponId: null,
          couponName: null,
          couponAmount: null
        },

        orderStatus: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOrderScript(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 详情信息 */
    detail(row, ids) {
      this.reset()
      this.formType = 0
      const id = row ? row.id : ids
      getOrderScript(id).then(response => {
        this.form = response.data
        this.form.discountInfo = JSON.parse(this.form.discountInfo)
        this.open = true
        this.formTitle = '预约打本订单详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrderScript(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrderScript(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
